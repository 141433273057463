import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">20 X 20 / 上半場</h2>
          <h1 className="main_title">鄭鴻旗</h1>
        </div>

        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_5">
                <div className="context">
                  <p>鄭鴻旗表示在學校時對科技藝術的學習缺乏系統，只好透過研討會或網路建立社群進行交流，之後具體空間的需求浮現，才落腳於寶藏巖國際藝術村。他們的空間被視為Maker Space──創客空間、自造空間，希望讓動手做、自學、共學、學新科技、學老東西的可能可以在這裡發生。</p>
                  <p>城市需要很多Maker Space，在社宅裡是否需要Maker Space呢？就像社區圖書館滿足喜歡閱讀的人，社區Maker Space可以滿足喜歡動手做、創新、創作的人。 feeder、養蜂場、雨水花園、一個擁有動植物或昆蟲的小型自然博物館。如環境藝術家Vaughn Bell與植物學家Pati Vitt的計畫─Local Home，將雨水花園設計轉化為作品展出。或東京都的摩天樓種蜜園，利用透明蜂巢可觀察到另一個昆蟲社群，體覺除了人類還有另外的動物社群生活於一個共同環境中。沈伯丞再舉《Sierra Remote Observatories》一個於社區大樓頂端設置望遠鏡的藝術計畫，提供住戶觀察接觸該生活區域、不同季節的星空；而展覽《Art as Aftershock》將地震紀錄轉化成圖像、聲音，讓人聽見、看見該場域曾發生大地震動的變化。透過這些計畫進一步思考、感覺自然與氣候，或許可以帶來觀看生活空間不同的視角，也可以是凝聚社區共同意識的方式。</p>
                  <p>Maker Space的經營除了新科技，也有舊科技混雜其中，是一個新舊科技混雜，鼓勵大家動手創作的地方。Maker Space從透過線上虛擬社群交流，延伸至實體空間社群交流聚會或工作坊，創造更多的自學跟共學。</p>
                  <p className="onset_right_1">鄭鴻旗進一步提出他的觀察，Maker Space也成為混齡學習的地方，讓大家學中做，做中學，以及共享資源。這是社會生活環境裡很重要的根本，也是目前教育體制無法提供的。鄭鴻旗特別提出古風里小白屋的案例來分享，它是里民中心，也是網絡社群的交流之處。就像過去的矽谷車庫，是一個創新發明培養皿，讓創意孕育，計畫成形，瘋狂點子實驗的地方。</p>
                </div>
            </div>
            </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_1_4" className="next_page">
            <div className="next_title">賴映如</div>
            <div className="next_owner">20 X 20 / 上半場</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
